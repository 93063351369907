import { Component, Inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DataAlert } from '../../dto/dto';

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.less',
})
export class SnackbarComponent implements OnInit {
  icon: string;
  type: string;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DataAlert) {}

  ngOnInit() {
    this.type = this.data.snackType;
    switch (this.data.snackType) {
      case 'success':
        this.icon = 'check_circle_outline';
        break;
      case 'error':
        this.icon = 'highlight_off';
        break;
      case 'warn':
        this.icon = 'error_outline';
        break;
      case 'info':
        this.icon = 'error_outline';
        break;
    }
  }

  closeSnackbar() {
    this.data.snackBar.dismiss();
  }
}
