import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private _snackBar: MatSnackBar) {}

  public openSnackBar(message: string,  snackType: string="success") {
    
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 3000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: { message: message, snackType: snackType,snackBar: this._snackBar },
    });
  }
}
